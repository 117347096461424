<template>
  <v-row class="mt-5" justify="center">
    <v-col cols="12" class="d-sm-flex justify-end">
      <action-button style="height: 43px" class="white--text font-weight-bold mr-5 my-2" large rounded :disabled="sales.length < 1 ||
        downloadButtonLoader ||
        ((!user.permissions.reportsDownload ||
          !user.permissions.reportsDownload.update) &&
          user.role == 'ORGANIZATION_USER')
        " @click="downloadReport()" :loading="downloadButtonLoader" :block="$vuetify.breakpoint.xsOnly">
        <v-icon>mdi-download</v-icon>
        Descargar Reporte
      </action-button>

      <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="50" offset-y left bottom
        origin="center center" transition="scale-transition" rounded="xl">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip top max-width="350">
            <template v-slot:activator="{ on: tooltip }">
              <v-btn class="font-weight-bold my-2" color="secondary" rounded v-bind="attrs" large
                v-on="{ ...tooltip, ...menu }" @click="formatMethods" :disabled="!user.permissions.Event || !user.permissions.Event.update
                  " :block="$vuetify.breakpoint.xsOnly">
                <v-icon left> mdi-cash-multiple </v-icon>
                Métodos de pago
              </v-btn>
            </template>
            <span>Selecciona los métodos de pago que estarán disponibles en el
              registro de tu membresia</span>
          </v-tooltip>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img src="@/assets/images/weChamber/wechamber-blue-logo-placeholder.png" alt="WeChamber logo" />
              </v-list-item-avatar>

              <v-list-item-content>
                <!--<v-list-item-title>Selecciona los métodos de pago disponibles en tu evento</v-list-item-title>-->
                <p class="font-weight-bold subtitle-1 mb-0">
                  Selecciona los métodos de pago disponibles en tu membresía
                </p>
                <p class="secondary--text mb-0">Recuerda guardar cambios</p>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch v-model="paymentsMethodsAvailables.card" color="amber"></v-switch>
              </v-list-item-action>
              <v-list-item-title>Tarjeta crédito/débito</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-switch v-model="paymentsMethodsAvailables.cash" color="amber"></v-switch>
              </v-list-item-action>
              <v-list-item-title>Efectivo</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-switch :disabled="!alertVisible && user.stripeAccountType != 'EXPRESS'
                  " v-model="paymentsMethodsAvailables.bankTransfer" color="amber"></v-switch>
              </v-list-item-action>
              <v-list-item-title>
                <div v-if="!alertVisible && user.stripeAccountType != 'EXPRESS'">
                  <v-menu open-on-hover activator="#menuActivator" top offset-y class="pa-5" max-width="300">
                    <v-list>
                      <v-list-item>
                        <span>No se ha activado el método de pago por SPEI, Hacer
                          click
                          <a href="/mi-cuenta" class="spei-hover">aquí</a> para
                          activarlo.</span>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <div id="menuActivator">
                    <v-icon color="red" size="25" class="pointer pr-2">mdi-alert-circle
                    </v-icon>
                    <span>Transferencia Bancaria SPEI</span>
                  </div>
                </div>
                <span v-else>Transferencia Bancaria SPEI</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="font-weight-bold black--text mx-2" color="grey lighten-3" elevation="0" rounded
              @click="menu = false">
              Cancelar
            </v-btn>
            <v-btn class="font-weight-bold black--text mx-2" color="accent" elevation="0" rounded :disabled="!user.permissions.Members || !user.permissions.Members.update
              " @click="updatePaymentMethods()">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-col>

    <Pagination :searchByName="true" :pagination="pagination" @search="search" />
    <v-col cols="12">
      <v-data-table :headers="cols" :items="sales" item-key="_id" class="elevation-1" v-if="sales.length > 0"
        :footer-props="{ 'items-per-page-options': [-1, 15, 30, 50, 100] }" hide-default-footer>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip class="pa-2" :color="paymentStatuses[item.status].color" text-color="white">
            {{ paymentStatuses[item.status].text }}
          </v-chip>
        </template>
        <template #[`item.actions`]="{ item }">
          <td :class="$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
            ">
            <div :class="$vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-row__cell'
              : ''
              " class="d-flex justify-center">
              <!-- <v-btn
                icon
                color="black"
                @click="downloadInvoice(item.invoice)"
                :disabled="
                  !item.invoice ||
                  ((!user.permissions.reportsDownload ||
                    !user.permissions.reportsDownload.update) &&
                    user.role == 'ORGANIZATION_USER')
                "
              >
                <v-icon>mdi-download</v-icon>
              </v-btn> -->
              <v-btn fab text small class="pa-0 ma-0" elevation="0" @click="item.dialog = true">
                <v-icon :color="color.secondary"> mdi-pencil </v-icon>
                <personalized-dialog style="display: none" :dialog="item.dialog" @save="
                  updateOrderInfo(item.id, item.email), (item.dialog = false)
                  " @discard="item.dialog = false">
                  <template v-slot:alertName> Modificar Información </template>
                  <template v-slot:alertContent>
                    <v-text-field label="Correo elecrónico" filled v-model="item.email" rounded dense
                      :rules="emailRules">
                    </v-text-field>
                  </template>
                </personalized-dialog>
              </v-btn>
              <DocumentsDialog :invoiceId="item.invoiceId" :id="item.id" :pdf="example"
                :paymentMethod="item.paymentMethod" :reference="item.reference" @setDocuments="generateDocuments"
                @getInvoice="downloadInvoice" @setEmail="fowardReferenceEmail">
              </DocumentsDialog>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog v-model="reportDialog" width="800" persistent>
      <ReportDialog :reportProgress="reportProgress" :payoutsLength="reportTotalPages" @cancel="cancelReport = true" />
    </v-dialog>
  </v-row>
</template>
<script>
import qs from "query-string";
import moment from "moment";
import Pagination from "../../../../../components/dashboard/pagination/Pagination.vue";
import ActionButton from "@/components/shared/ActionButton";
import Endpoints from "@/share/Endpoints";
import { DefaultDateFormat } from "@/share/constants";
import { mapState, mapActions, mapMutations } from "vuex";
import ReportDialog from "@/components/shared/Reports/ReportDialog";
import * as XLSX from "xlsx/xlsx.mjs";
import { colors } from "@/constants/colors.js";
import PersonalizedDialog from "../../../../../components/shared/PersonalizedDialog.vue";
import { validations } from "@/share/validations.js";
import {
  getRangePeriod,
  getDefaultRangePeriod,
  formatPrice,
  downloadFile,
  downloadLink,
} from "@/utils";

import DocumentsDialog from "../../../../../components/shared/DocumentsDialog.vue";

export default {
  components: {
    ActionButton,
    Pagination,
    ReportDialog,
    PersonalizedDialog,
    DocumentsDialog,
  },
  props: ["affiliationId"],
  data: () => ({
    reportDialog: false,
    menu: false,
    reportProgress: 0,
    reportTotalPages: 1,
    cancelReport: false,
    downloadButtonLoader: false,
    // loading: false,
    emailRules: validations.emailValidation({ required: false }),
    color: colors,
    sales: [],
    orders: [],
    example: "",
    originalOrders: {},
    pagination: {
      page: 1,
      limit: 10,
      totalPages: 0,
      gte: null,
      lte: null,
      name: "",
    },
    cols: [
      {
        text: "Nombre",
        value: "nameFormatted",
        align: "center",
      },
      {
        text: "Total",
        value: "totalFormatted",
        align: "center",
      },
      {
        text: "Método de pago",
        value: "paymentMethodFormatted",
        align: "center",
      },
      {
        text: "Estatus",
        value: "status",
        align: "center",
      },
      {
        text: "Fecha de creación",
        value: "createdAt",
        align: "center",
      },
      {
        text: "Fecha de actualización",
        value: "updatedAt",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
      },
    ],
    reportCols: [
      {
        text: "Nombre",
        value: "nameFormatted",
        align: "center",
      },
      {
        text: "Email",
        value: "nameFormatted",
        align: "center",
      },
      {
        text: "Fecha de creación",
        value: "totalFormatted",
        align: "center",
      },
      {
        text: "Fecha de actualización",
        value: "paymentMethodFormatted",
        align: "center",
      },
      {
        text: "Metodo de pago",
        value: "status",
        align: "center",
      },
      {
        text: "Estatus",
        value: "updatedAt",
        align: "center",
      },
      {
        text: "Total",
        value: "createdAt",
        align: "center",
      },
      {
        text: "Razón social",
        align: "center",
        value: "actions",
      },
      {
        text: "Folio de factura",
        align: "center",
        value: "actions",
      },
      {
        text: "Facturas",
        value: "actions",
        align: "center",
      },
    ],
    orderStatuses: {
      COMPLETED: "Completado",
      PAYMENT_PENDING: "Pendiente",
      IN_PROGRESS: "En Progreso",
      EXPIRED: "Expirado",
      FREE: "Cupón",
    },
    paymentMethods: {
      CREDIT_CARD: "Tarjeta de Crédito/Débito",
      DEBIT_CARD: "Tarjeta de Débito",
      BANK_TRANSFER: "Transferencia Bancaria SPEI",
      CASH: "Efectivo (Oxxo)",
      FREE: "Cupón",
      MANUALLY_COMPLETED: 'Activado manualmente'
    },
    paymentStatuses: {
      COMPLETED: {
        text: "Completado",
        color: "green",
      },
      PAYMENT_PENDING: {
        text: "Pendiente",
        color: "accent",
      },
      IN_PROGRESS: {
        text: "En Progreso",
        color: "blue",
      },
      EXPIRED: {
        text: "Expirado",
        color: "red",
      },
      FREE: {
        text: "Cupón",
        color: "blue",
      },
    },
    reportPaymentMethods: {
      CREDIT_CARD: "Tarjeta de Crédito/Débito",
      DEBIT_CARD: "Tarjeta de Débito",
      BANK_TRANSFER: "Transferencia Bancaria SPEI",
      CASH: "Efectivo (Oxxo)",
      FREE: "Cupón",
      MANUALLY_COMPLETED: 'Activado manualmente'
    },
    paymentsMethodsAvailables: {
      card: false,
      cash: false,
      bankTransfer: false,
    },
    afiliation: null,
  }),
  created() {
    this.getAfiliation();

    const range = getDefaultRangePeriod();

    const format = "YYYY-MM-DD";
    (this.pagination.gte = moment(range.startDate).format(format)),
      (this.pagination.lte = moment(range.endDate).format(format)),
      this.getSales();
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("alert", ["alertVisible"]),
  },
  methods: {
    ...mapActions("affiliation", ["fetchAffiliationById"]),
    ...mapMutations("notification", ["show"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    search(Pagination) {
      this.pagination = Pagination;
      this.getSales();
    },
    formatSalesData(order) {
      const { createdAt, updatedAt, status, total } = order;

      let name = "N/A",
        lastName = "N/A",
        fullname = "",
        paymentMethod = "N/A";

      if (order.paymentInformation) {
        name = ["INSCRIPTION", "DEBT"].includes(order.type)
          ? `${order.member.name} ${order.member.firstLastname}`
          : order.paymentInformation.name
            ? order.paymentInformation.name
            : "";
        lastName = order.paymentInformation.lastName
          ? order.paymentInformation.lastName
          : "";
        paymentMethod = order.paymentInformation.paymentMethod
          ? order.paymentInformation.paymentMethod
          : "";
      }
      if (name.includes(lastName)) {
        fullname = name;
      } else {
        fullname = (name + ' ' + lastName).replace("undefined", "");
      }

      return {
        ...order?.paymentInformation,
        id: order._id,
        invoiceId: order.invoice,
        createdAt: moment(createdAt).format(DefaultDateFormat),
        updatedAt: moment(updatedAt).format(DefaultDateFormat),
        status: status,
        dialog: false,
        reference: order.paymentInformation.referenceLink
          ? true
          : order.paymentReference
            ? true
            : false,
        totalFormatted: formatPrice(total),
        nameFormatted: `${["INSCRIPTION", "DEBT"].includes(order.type) ? name : fullname
          }`,
        paymentMethodFormatted: this.paymentMethods[paymentMethod] || "N/A",
      };
    },
    async updateOrderInfo(id, email) {
      let orderIndex = this.originalOrders.findIndex(
        (event) => event._id == id
      );
      let order = this.originalOrders[orderIndex];
      order.paymentInformation.email = email;
      const response = await this.axios({
        method: "PUT",
        url: `/orders/${order._id}`,
        data: {
          paymentInformation: order.paymentInformation,
        },
      });
      if (response.status === 200) {
        this.show({
          text: "Datos actualizados exitosamente!",
          color: "success",
          time: 3000,
        });
      } else {
        this.show({
          text: "Ha ocurrido un error!",
          color: "error",
          time: 3000,
        });
      }
    },
    generateDocuments(id) {
      // subir cambios
    },
    async fowardReferenceEmail(id, type) {
      try {
        const response = await this.axios({
          method: "POST",
          url: Endpoints.invoicesSendEmail,
          data: {
            orderId: id,
            type: type,
          },
        });
        if (response.status === 200) {
          this.show({
            text: "Correo reenviado exitosamente!",
            color: "success",
            time: 3000,
          });
        }
      } catch (error) {
        const errorLog = error?.response?.data?.error?.message || 'Ha ocurrido un error al reenviar el correo.'
        this.show({
          text: errorLog,
          color: "error",
          time: 3000,
        });
      }
    },
    async downloadInvoice(id, download = false, type = "") {
      console.log(id, download, type);
      this.example = "";
      try {
        if (type == "reference") {
          const index = this.orders.findIndex((order) => order._id === id);
          this.example =
            this.orders[index].paymentInformation.referenceLink ||
            this.orders[index].paymentReference;
          if (download) {
            downloadLink(this.example);
          }
        } else {
          const payload = {
            orderId: id,
            isPreview: !download,
          };
          this.loading();
          const response = await this.axios.post(
            Endpoints.invoicesDownload,
            payload,
            { responseType: "arraybuffer" }
          );
          if (response.status === 200) {
            const { data } = response;
            if (download) {
              downloadFile(data, "application/zip", "invoice.zip");
            } else {
              console.log(response);
              let blob = new File([data], "invoice.pdf", {
                type: `application/pdf`,
              });
              const fileUrl = URL.createObjectURL(blob);
              this.example = fileUrl;
              console.log(fileUrl);
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
      }
    },
    async getSales() {
      const endpoint = qs.stringifyUrl({
        url: Endpoints.affiliationMembershopOrders.replace(
          /:affiliationId/,
          this.affiliationId
        ),
        query: this.pagination,
      });
      const response = await this.axios.get(endpoint);
      if (response.status === 200) {
        const { orders } = response.data;
        this.orders = orders;
        this.originalOrders = orders;
        this.pagination.page = response.data.page;
        this.pagination.totalPages = response.data.totalPages;
        this.sales = orders.map(this.formatSalesData);
      }
    },
    async updatePaymentMethods() {
      try {
        this.loading();

        this.afiliation.affiliation.paymentMethods.forEach((method) => {
          if (method.name == "CREDIT_CARD") {
            method.active = this.paymentsMethodsAvailables.card;
          } else if (method.name == "CASH") {
            method.active = this.paymentsMethodsAvailables.cash;
          } else if (method.name == "BANK_TRANSFER") {
            method.active = this.paymentsMethodsAvailables.bankTransfer;
          }
        });

        const payload = {
          affiliation: {
            ...this.afiliation.affiliation,
          },
        };
        const response = await this.axios.put(
          Endpoints.affiliations + "/" + this.affiliationId,
          payload
        );
        if (response.status === 200) {
          const { affiliate } = response.data;
        }

        this.show({
          text: "Actualizado de forma correcta.",
          color: response.status ? "primary" : "error",
        });
        // Obtener los datos del evento nuevamente;

        this.menu = false;
      } catch (error) {
        console.log(error);
        this.show({
          text: "¡Ha ocurrido un problema al querer actualizar los métodos de pago disponibles!",
          color: "error",
        });
      } finally {
        this.loaded();
      }
    },
    async getAfiliation() {
      this.afiliation = await this.fetchAffiliationById(this.affiliationId);
    },
    // async downloadInvoice(id) {
    //   const payload = {
    //     invoiceInformation: { id },
    //   };
    //   const response = await this.axios.post(
    //     Endpoints.invoicesDownload,
    //     payload,
    //     { responseType: "arraybuffer" }
    //   );
    //   if (response.status === 200) {
    //     const { data } = response;
    //     downloadFile(data, "application/zip", "invoice.zip");
    //   }
    // },
    async downloadReport() {
      this.reportDialog = true;
      this.reportProgress = 0;
      let reportArray = [];
      let hasMore = true;

      while (hasMore) {
        if (this.cancelReport == true) {
          this.cancelReport = false;
          this.reportDialog = false;
          return;
        }

        const response = await this.axios({
          url: `/orders/affiliation-membership/${this.affiliationId
            }/report?gte=${this.pagination.gte}&lte=${this.pagination.lte}&page=${this.reportProgress + 1
            }&limit=10`,
          method: "GET",
        });
        if (response.status == 200) {
          reportArray = reportArray.concat(response.data.report.orders);
          hasMore = response.data.report.hasNextPage;
          this.reportTotalPages = response.data.report.totalPages;
          this.reportProgress++;
        } else {
          hasMore = false;
        }
      }

      if (this.cancelReport == true) {
        this.cancelReport = false;
        this.reportDialog = false;
        return;
      }

      const ws = XLSX.utils.json_to_sheet(reportArray);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Ventas de Membrsia");
      XLSX.writeFile(wb, "VentasMembresia.xlsx");

      this.reportDialog = false;
    },
    formatMethods() {
      this.afiliation.affiliation.paymentMethods.forEach((method) => {
        if (method.name == "CREDIT_CARD") {
          this.paymentsMethodsAvailables.card = method.active;
        } else if (method.name == "CASH") {
          this.paymentsMethodsAvailables.cash = method.active;
        } else if (method.name == "BANK_TRANSFER") {
          this.paymentsMethodsAvailables.bankTransfer = method.active;
        }
      });
    },
  },
};
</script>
<style scoped>
.spei-hover {
  font-weight: 800;
}
</style>